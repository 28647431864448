<template>
  <Modal v-model="showProductDetail" width="1000" footer-hide :styles="{top: '40px'}">
    <p class="text-center m-b-10">
      <Button type="primary" @click="updateData">保存并提交</Button>
    </p>

    <TinyEditor v-if="showProductDetail" v-model="productHtml"></TinyEditor>
  </Modal>
</template>

<script>
import TinyEditor from '@/components/common/TinyEditor'
import { HTMLEncode } from '@/utils'
import { getHtml, updateHtml } from '@/api/product/cloudinventory'

export default {
  components: {
    TinyEditor
  },
  data () {
    return {
      showProductDetail: false,
      inventoryId: null,
      productHtml: ''
    }
  },
  methods: {
    showModal (inventoryId) {
      this.showProductDetail = true
      this.productHtml = ''
      this.inventoryId = inventoryId
      this.initData()
    },
    initData () {
      const queryModel = {
        inventoryId: this.inventoryId
      }

      getHtml(queryModel).then(res => {
        this.productHtml = res
      })
    },
    updateData () {
      const updateModel = {
        inventoryId: this.inventoryId,
        html: HTMLEncode(this.productHtml)
      }

      updateHtml(updateModel).then(res => {
        this.$Notice.success({ desc: '数据保存成功！' })
      })
    }
  }
}
</script>

<style scoped>
.product-detail{
  background-color: white;
  color: black;
  padding: 10px;
}
</style>
